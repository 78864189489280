import * as React from 'react'
import { Icon } from '@components'
import type { InfoSheetProps } from './InfoSheet.types'
import { useTheme } from '@core/utils'

const InfoSheet: React.FC<InfoSheetProps> = ({ title, description, error, IconProps }) => {
  const theme = useTheme().colors

  return (
    <div className="container flex flex-col items-center justify-center gap-14">
      <div className="flex h-[160px] w-[160px] items-center justify-center rounded-full bg-semantic-background-primary">
        <Icon name="connection_error" size={56} color={theme.semantic.content.inkLight} {...IconProps} />
      </div>
      <div className="flex flex-col gap-12 text-center">
        <div className="space-y-6">
          <h2 className="text-display-hg text-semantic-content-ink">{title}</h2>
          <p className="text-body-lg-r text-semantic-content-inkLight">{description}</p>
        </div>
        {error && <strong className="text-overline-md text-semantic-content-inkLight">{error}</strong>}
      </div>
    </div>
  )
}
export default InfoSheet

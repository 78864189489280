import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@components'
import type { NavigationProps } from './Navigation.types'
import { cn, useTheme } from '@core/utils'

const Navigation: React.FC<NavigationProps> = ({ onBackPress, title, LayoutRightComponent, className, ...props }) => {
  const theme = useTheme().colors
  const navigate = useNavigate()

  const handleNavigate = React.useCallback(() => {
    if (onBackPress) return onBackPress()

    navigate(-1)
  }, [onBackPress, navigate])

  React.useEffect(() => {
    const root = document.querySelector('#root')
    if (!root) return undefined

    root.classList.add('pt-[var(--navigation-height)]')

    return () => {
      root.classList.remove('pt-[var(--navigation-height)]')
    }
  }, [])

  return (
    <div className="animate-enter fixed top-0 z-50 w-full">
      <div
        className={cn(
          'relative flex h-[50px] items-center gap-2 border-b border-tint-white-10 bg-semantic-content-brand px-4 py-[10px]',
          className,
        )}
        {...props}>
        <button type="button" className="shrink-0" onClick={handleNavigate}>
          <Icon name="arrow-left" color={theme.semantic.content.inkInverse} size={24} />
        </button>
        <div className="flex-1 text-center">
          <h1 className="absolute left-1/2 top-1/2 line-clamp-1 -translate-x-1/2 -translate-y-1/2 text-display-xs text-semantic-background-inkInverse">
            {title}
          </h1>
        </div>
        <div className="flex shrink-0">{LayoutRightComponent && LayoutRightComponent}</div>
      </div>
    </div>
  )
}
export default Navigation

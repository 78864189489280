import * as React from 'react'
import { cn } from '@core/utils'
import { BottomSheetPortal } from './BottomSheet'
import BottomSheetOverlay from './BottomSheetOverlay'

import { Drawer as BottomSheetPrimitive } from 'vaul'

const BottomSheetContent = React.forwardRef<
  React.ElementRef<typeof BottomSheetPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof BottomSheetPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <BottomSheetPortal>
    <BottomSheetOverlay />
    <BottomSheetPrimitive.Content
      ref={ref}
      className={cn(
        'fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border bg-semantic-content-inkInverse',
        className,
      )}
      {...props}>
      <div className="mx-auto mt-4 h-2 w-[100px] shrink-0 rounded-full bg-semantic-background-tertiary" />
      {children}
    </BottomSheetPrimitive.Content>
  </BottomSheetPortal>
))
BottomSheetContent.displayName = 'BottomSheetContent'

export default BottomSheetContent

import { Icon } from '@components'
import type { IconProps } from '../Icon/Icon.types'
import type { GetIconComponentProps } from './Button.types'

export const IconSize = (size?: string) => {
  switch (size) {
    case 'tiny':
      return 12
    case 'small':
      return 16
    case 'medium':
      return 24
    case 'large':
      return 24
    default:
      return 24
  }
}

export const getIconComponents = (props: GetIconComponentProps) => {
  const { leftIcon, rightIcon, size } = props

  const generateIcon = (iconName: IconProps['name']) => <Icon name={iconName} size={IconSize(size)} />

  return {
    leftIconComponent: leftIcon && generateIcon(leftIcon),
    rightIconComponent: rightIcon && generateIcon(rightIcon),
  }
}

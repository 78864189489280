import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { Icon } from '@components'
import { cn } from '@core/utils'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-center justify-between pb-1 pt-[2px] !text-body-md-b text-semantic-content-ink  transition-all [&[data-state=open]>svg]:rotate-180',
        className,
      )}
      {...props}>
      {children}
      <Icon className="shrink-0 transition-transform duration-200" name="caret-down" size={24} />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

export default AccordionTrigger

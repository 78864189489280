import { find, get } from 'lodash'
import type { ProductCartType } from '@cart/slice'
import type { ProductDetailVariationsItem } from '@products/api'

export const getVariationsPrice = (variations: ProductDetailVariationsItem[]) => {
  return variations.find(variation => variation.price)?.price ?? 0
}

export const getProductByVariationId = (cart: ProductCartType[], variationId: number | null) => {
  return find(cart, product => product.variation.id === variationId)
}

export const getMaxQuantity = (product?: ProductCartType) => {
  return get(product, 'variation.max_quantity', 0)
}

export const getProductQuantity = (product?: ProductCartType) => {
  return get(product, 'quantity', 0)
}

import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit'
import { values } from 'lodash'
import { emitter } from '@core/utils'

// eslint-disable-next-line
const handleError: Middleware = _store => next => action => {
  if (isRejectedWithValue(action)) {
    let message: string = action?.payload?.data?.message
    if (action.payload.status === 401) return

    if (action.payload.status === 422) {
      const errors = action.payload.data.errors
      const errorMessages = values(errors)
      if (errorMessages?.[0]?.[0]) message = errorMessages[0][0]
    }

    if (action.payload.status === 404) {
      return (window.location.href = '/404')
    }

    emitter.emit('addToast', { variant: 'error', message: message, icon: 'warning', duration: 5000 })
  }
  return next(action)
}

export default {
  handleError: handleError,
}

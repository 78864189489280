import React from 'react'
import iconsConfig from '@core/assets/fonts/macellan-icons-config.json'
import type { IconProps } from './Icon.types'

const Icon: React.FC<IconProps> = ({ name, size, color }) => {
  const icon = iconsConfig.glyphs.find(glyph => glyph.css === name)

  if (typeof name !== 'string') {
    return null
  }

  if (!icon) return null

  return (
    <svg width={size} height={size} fill={color} className="animate-enter" viewBox="0 0 1000 1000">
      <path d={icon.svg.path} />
    </svg>
  )
}

export default Icon

import * as React from 'react'
import type { BottomSheetProps, BottomSheetStaticProperties } from './BottomSheet.types'
import BottomSheetContent from './BottomSheetContent'
import BottomSheetDescription from './BottomSheetDescription'
import BottomSheetFooter from './BottomSheetFooter'
import BottomSheetHeader from './BottomSheetHeader'
import BottomSheetTitle from './BottomSheetTitle'

import { Drawer as BottomSheetPrimitive } from 'vaul'

const BottomSheet: React.ComponentProps<BottomSheetProps> & BottomSheetStaticProperties = ({
  shouldScaleBackground = true,
  ...props
}) => <BottomSheetPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />

export const BottomSheetTrigger = BottomSheetPrimitive.Trigger

export const BottomSheetPortal = BottomSheetPrimitive.Portal

export const BottomSheetClose = BottomSheetPrimitive.Close

BottomSheet.Trigger = BottomSheetTrigger
BottomSheet.Close = BottomSheetClose
BottomSheet.Content = BottomSheetContent
BottomSheet.Header = BottomSheetHeader
BottomSheet.Title = BottomSheetTitle
BottomSheet.Description = BottomSheetDescription
BottomSheet.Footer = BottomSheetFooter

export default BottomSheet

import * as React from 'react'
import * as ToastPrimitives from '@radix-ui/react-toast'
import { Icon } from '@components'
import type { ToastProps, ToastStaticProperties } from './Toast.types'
import { useNotification } from '@core/hooks'
import { cn } from '@core/utils'
import { toastStyles } from './Toast.styles'
import ToastAction from './ToastAction'

const ToastBase = React.forwardRef<HTMLDivElement, ToastProps>(
  ({ id, open = 'fade', duration, variant, className, icon, message, LayoutRightComponent }, ref) => {
    const { dismiss } = useNotification()

    React.useEffect(() => {
      if (open && duration) {
        const timer = setTimeout(() => {
          dismiss(id)
        }, duration)
        return () => clearTimeout(timer)
      }
    }, [dismiss, duration, id, open])

    return (
      <div ref={ref} data-state={open} className={cn(toastStyles({ variant, className }))}>
        <div className="flex items-center gap-2">
          {icon ? <Icon name={icon} size={24} /> : null}
          <span className="text-body-sm-r leading-none">{message}</span>
        </div>
        {LayoutRightComponent ? (
          <div>{typeof LayoutRightComponent === 'function' ? LayoutRightComponent(variant) : LayoutRightComponent}</div>
        ) : null}
      </div>
    )
  },
)

const Toast: React.FC<ToastProps> & ToastStaticProperties = props => {
  return <ToastBase {...props}>{props.children}</ToastBase>
}

Toast.displayName = ToastPrimitives.Root.displayName

Toast.Action = ToastAction

export default Toast

import { cva } from 'class-variance-authority'

export const badgeStyles = cva(
  [
    'flex',
    'justify-center',
    'items-center',
    'gap-1',
    '!rounded-full',
    'focus:outline-none',
    'disabled:cursor-not-allowed',
    'disabled:bg-semantic-background-primary',
    'disabled:!text-semantic-content-disabled',
    'disabled:fill-semantic-content-disabled',
    'disabled:after:hover:!bg-transparent',
    'transition duration-300 ease-in-out',
    'relative',
    'overflow-hidden',
    'border-[1.6px]',
    'border-transparent',
    'focus:ring-2',
    'animate-in fade-in',
  ],
  {
    variants: {
      variant: {
        'brand': [
          'bg-solid-brand-5',
          '!border-solid-brand-5',
          '!text-semantic-content-inkInverse fill-semantic-background-inkInverse',
          'focus:ring-tint-brand-20',
        ],
        'brand-tonal': [
          'bg-tint-brand-10',
          '!border-tint-brand-10',
          '!text-semantic-content-brand fill-semantic-content-brand',
          'focus:ring-tint-brand-5',
        ],
        'ink': [
          'bg-semantic-content-ink',
          '!border-semantic-content-ink',
          '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
          'focus:ring-tint-ink-20',
        ],
        'ink-tonal': [
          'bg-solid-ink-1',
          '!border-solid-ink-1',
          '!text-semantic-content-inkLight fill-semantic-content-inkLight',
          'focus:ring-tint-white-20',
        ],
        'yellow': [
          'bg-solid-yellow-2',
          '!border-solid-yellow-2',
          '!text-semantic-content-ink fill-solid-ink-9',
          'focus:ring-tint-yellow-20',
        ],
        'ink-inverse': [
          'bg-semantic-content-inkInverse',
          '!border-semantic-content-inkInverse',
          '!text-semantic-content-ink fill-semantic-content-ink',
          'focus:ring-tint-white-20',
        ],
        'ink-inverse-tonal': [
          'bg-tint-white-20',
          '!border-bg-tint-white-20',
          '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
          'focus:ring-tint-white-5',
        ],
        'outlined': [
          'bg-transparent',
          '!border-semantic-background-tertiary',
          '!text-semantic-content-ink fill-semantic-content-ink',
          'focus:ring-tint-white-20',
        ],
        'bg-blurred': [
          'bg-tint-white-60',
          '!border-tint-white-60',
          '!text-semantic-content-ink fill-solid-brand-5',
          'focus:ring-tint-white-20',
        ],
      },
      size: {
        tiny: ['px-1', 'py-[2px]', 'text-body-sm-b', 'h-4'],
        small: ['px-2', 'py-1', 'text-body-sm-b', 'h-6'],
        medium: ['px-3', 'py-2', 'text-body-md-b', 'h-8'],
        large: ['px-4', 'py-2', 'text-body-md-b', 'h-10'],
        huge: ['px-6', 'py-3', 'text-body-md-b', 'h-12'],
      },
    },
    defaultVariants: {
      variant: 'brand',
      size: 'large',
    },
  },
)

import * as React from 'react'
import type { AlertOverlayProps } from './Alert.types'
import { cn } from '@core/utils'

const AlertOverlay = React.forwardRef<HTMLDivElement, AlertOverlayProps>(({ open, className, ...props }, ref) => (
  <div
    data-state={open}
    className={cn(
      'fixed inset-0 bg-tint-ink-80 data-[state=true]:animate-in data-[state=false]:animate-out data-[state=false]:fade-out-0 data-[state=true]:fade-in-0',
      className,
    )}
    {...props}
    ref={ref}
  />
))

AlertOverlay.displayName = 'AlertOverlay'

export default AlertOverlay

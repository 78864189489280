import { createSlice } from '@reduxjs/toolkit'
import baseApi from '@core/api'
import type { BaseState } from './BaseSlice.types'

const initialState: BaseState = {
  info: null,
}

const BaseSlice = createSlice({
  name: 'baseSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(baseApi.endpoints.info.matchFulfilled, (state, action) => {
      state.info = action.payload.data
    })
  },
})

export default BaseSlice.reducer

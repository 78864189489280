import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { replace } from 'lodash'
import { Spinner } from '@components'
import baseApi from '@core/api'
import { ThemeMiddlewares } from '@core/middlewares'
import { Products } from '@products/views'

const Company: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const InfoQuery = baseApi.useInfoQuery({ slug: replace(location.pathname, '/', '') })

  const info = React.useMemo(() => InfoQuery.data?.data, [InfoQuery])

  React.useEffect(() => {
    if (!location.pathname) return navigate('/404', { replace: true })
    if (!info) return

    ThemeMiddlewares.SetTheme(info.color)
  }, [info, location.pathname, location.search, navigate])

  if (InfoQuery.isLoading)
    return (
      <div className="grid h-screen place-items-center">
        <Spinner size={32} />
      </div>
    )

  return <Products />
}
export default Company

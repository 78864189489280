import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Accordion } from '@components'
import type { ProductDetailAllergiesProps } from './ProductDetail.types'

const ProductDetailAllergies: React.FC<ProductDetailAllergiesProps> = ({ allergies }) => {
  const { t } = useTranslation()

  if (!allergies.length) return null

  return (
    <Accordion type="single" defaultValue="allergies" collapsible>
      <Accordion.Item value="allergies">
        <Accordion.Trigger>{t('product.allergies')}</Accordion.Trigger>
        <Accordion.Content className="flex flex-col gap-3">
          {map(allergies, (allergy, index) => (
            <strong key={index} className="text-body-md-r text-semantic-content-inkMedium">
              {allergy.name}
            </strong>
          ))}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  )
}
export default ProductDetailAllergies

import baseApi from '@core/api'
import type { CategoriesData, CategoriesResponse, ProductDetailData, ProductDetailResponse } from './ProductsApi.types'

const productsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    categories: builder.query<CategoriesResponse, CategoriesData>({
      query: data => `menu/${data.slug}/categories`,
    }),
    productDetail: builder.query<ProductDetailResponse, ProductDetailData>({
      query: data => `menu/${data.menuSlug}/${data.categorySlug}/${data.productSlug}`,
    }),
  }),
  overrideExisting: false,
})

export default productsApi

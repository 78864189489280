import * as React from 'react'
import { cn } from '@core/utils'

import { Drawer as BottomSheetPrimitive } from 'vaul'

const BottomSheetOverlay = React.forwardRef<
  React.ElementRef<typeof BottomSheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof BottomSheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <BottomSheetPrimitive.Overlay ref={ref} className={cn('fixed inset-0 z-50 bg-black/80', className)} {...props} />
))
BottomSheetOverlay.displayName = BottomSheetPrimitive.Overlay.displayName

export default BottomSheetOverlay

import * as React from 'react'
import { Image } from '@components'
import type { ProductDetailImageProps } from './ProductDetail.types'

const ProductDetailImage: React.FC<ProductDetailImageProps> = ({ title, image }) => {
  return (
    <figure>
      <Image src={image} alt={title} className="max-h-[300px] min-h-[250px] w-full object-cover" />
    </figure>
  )
}
export default ProductDetailImage

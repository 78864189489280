import * as React from 'react'
import { cn } from '@core/utils'

import { Drawer as BottomSheetPrimitive } from 'vaul'

const BottomSheetTitle = React.forwardRef<
  React.ElementRef<typeof BottomSheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof BottomSheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <BottomSheetPrimitive.Title
    ref={ref}
    className={cn('text-lg font-semibold leading-none tracking-tight', className)}
    {...props}
  />
))
BottomSheetTitle.displayName = BottomSheetPrimitive.Title.displayName

export default BottomSheetTitle

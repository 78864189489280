import { cva } from 'class-variance-authority'

export const iconButtonStyles = cva(
  [
    'flex',
    'justify-center',
    'items-center',
    '!rounded-full',
    'focus:outline-none',
    'disabled:cursor-not-allowed',
    'disabled:bg-semantic-background-primary',
    'disabled:!text-semantic-content-disabled',
    'disabled:fill-semantic-content-disabled',
    'disabled:after:hover:!bg-transparent',
    'transition duration-300 ease-in-out',
    'relative',
    'overflow-hidden',
    'border-[1.6px]',
    'border-transparent',
    'after:hover:bg-tint-ink-10 after:content-[""] after:absolute after:inset-0 after:transition after:duration-300 after:ease-in-out',
    'animate-in fade-in',
  ],
  {
    variants: {
      variant: {
        'primary': [
          'bg-solid-brand-5',
          '!text-semantic-content-inkInverse fill-semantic-background-inkInverse',
          'focus:ring-tint-brand-20',
        ],
        'primary-tonal': [
          'bg-solid-brand-1',
          '!text-semantic-content-brand fill-semantic-content-brand',
          'focus:ring-tint-brand-10',
        ],
        'secondary': [
          'bg-transparent',
          'border-semantic-background-tertiary',
          '!text-semantic-content-ink fill-semantic-content-ink',
          'focus:ring-tint-ink-5',
        ],
        'secondary-on-dark': [
          'bg-transparent',
          'border-tint-white-40',
          '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
          'focus:ring-tint-white-40',
        ],
        'ink': [
          'bg-semantic-content-ink',
          'border-transparent',
          '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
          'focus:ring-semantic-content-ink/40',
          'after:hover:bg-tint-ink-10',
        ],
        'ink-tonal': [
          'bg-semantic-background-secondary',
          'border-transparent',
          '!text-semantic-content-ink fill-semantic-content-ink',
          'focus:ring-tint-ink-5',
        ],
        'ink-inverse': [
          'bg-solid-core-white',
          'border-transparent',
          '!text-semantic-content-ink fill-solid-ink-9',
          'focus:ring-tint-white-40',
        ],
        'ghost-on-dark': [
          'bg-solid-core-transparent',
          'border-transparent',
          '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
          'focus:ring-solid-core-white/40',
          'after:hover:!bg-semantic-content-inkInverse/10',
          'underline',
        ],
        'ghost-on-light': [
          'bg-solid-core-transparent',
          'border-transparent',
          '!text-semantic-content-ink fill-semantic-content-ink',
          'focus:ring-tint-ink-40',
          'underline',
        ],
        'bg-blurred': [
          'bg-tint-white-60',
          'border-transparent',
          '!text-semantic-content-ink fill-solid-ink-9',
          'focus:ring-tint-white-10',
        ],
      },
      size: {
        tiny: ['h-6 w-6', 'focus:ring-2'],
        small: ['h-8 w-8', 'focus:ring-2'],
        medium: ['h-10 w-10', 'focus:ring-4'],
        large: ['h-12 w-12', 'focus:ring-4'],
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'large',
    },
  },
)

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import i18n from '@core/translations'
import type { InfoData, InfoResponse } from '@core/api/BaseApi.types.ts'
import { Environment } from '@core/constants'

const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: Environment.BASE_URL,
    prepareHeaders: async headers => {
      const localization = i18n.getSupportedLocale()

      headers.append('X-Localization', localization)
    },
  }),
  endpoints: builder => ({
    info: builder.query<InfoResponse, InfoData>({
      query: data => {
        localStorage.clear()
        return {
          url: `/qr-codes/info?slug=${data.slug}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export default baseApi

import * as React from 'react'
import { Badge, IconButton } from '@components'
import type { SectionBarUtilsProps } from './SectionBar.types'

export const RenderLayoutRightComponent: React.FC<SectionBarUtilsProps> = ({
  LayoutRightComponent,
  LayoutRightComponentProps,
  MobileRightComponentProps,
  NavigateLink,
}) => {
  if (typeof LayoutRightComponent === 'object') {
    return LayoutRightComponent
  }

  if (typeof LayoutRightComponent === 'boolean') {
    return (
      <React.Fragment>
        <Badge
          variant="outlined"
          size="small"
          className="hidden hover:bg-semantic-background-tertiary lg:flex"
          onClick={NavigateLink}
          {...LayoutRightComponentProps}>
          {LayoutRightComponentProps?.children}
        </Badge>
        <IconButton
          variant="ghost-on-light"
          name="arrow-right"
          size="medium"
          className="focus:ring-2 lg:hidden"
          onClick={NavigateLink}
          {...MobileRightComponentProps}
        />
      </React.Fragment>
    )
  }

  return null
}

import * as React from 'react'
import { cn } from '@core/utils'

import { Drawer as BottomSheetPrimitive } from 'vaul'

const BottomSheetDescription = React.forwardRef<
  React.ElementRef<typeof BottomSheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof BottomSheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <BottomSheetPrimitive.Description
    ref={ref}
    className={cn('text-sm text-semantic-content-inkMedium', className)}
    {...props}
  />
))
BottomSheetDescription.displayName = BottomSheetPrimitive.Description.displayName

export default BottomSheetDescription

import { createSlice } from '@reduxjs/toolkit'
import { find, findIndex } from 'lodash'
import i18n from '@core/translations'
import type { CartBaseState } from './CartSlice.types'
import { emitter } from '@core/utils'

const initialState: CartBaseState = {
  products: [],
}

const CartSlice = createSlice({
  name: 'baseSlice',
  initialState: initialState,
  reducers: {
    setAddToCart: (state, action) => {
      const existingProduct = find(state.products, ['variation.id', action.payload.variation.id])

      if (existingProduct) {
        existingProduct.quantity += action.payload.quantity
        return
      }

      state.products.push(action.payload)
    },
    setRemoveFromCart: (state, action) => {
      const existingProductIndex = findIndex(state.products, ['variation.id', action.payload.variation.id])

      if (existingProductIndex !== -1) {
        if (action.payload.quantity > 1) {
          state.products[existingProductIndex].quantity -= 1
          return
        }

        state.products.splice(existingProductIndex, 1)
        return
      }

      emitter.emit('addToast', {
        variant: 'error',
        message: i18n.t('cart.product_not_in_basket', { name: action.payload.name }),
        icon: 'warning',
        duration: 5000,
      })
    },
    setClearCart: state => {
      state.products = []
    },
  },
})

export const { setAddToCart, setRemoveFromCart, setClearCart } = CartSlice.actions
export default CartSlice.reducer

import * as React from 'react'
import type { ToolBarProps, ToolBarStaticProperties } from './ToolBar.types'
import { cn } from '@core/utils'
import ToolBarLeft from './ToolBarLeft'
import ToolBarRight from './ToolBarRight'

const ToolBar: React.FC<ToolBarProps> & ToolBarStaticProperties = ({ className, ...props }) => {
  return (
    <div
      id="tool-bar"
      className={cn(
        'animate-enter flex items-center justify-between border-t border-semantic-background-primary bg-semantic-background-inkInverse p-6 lg:px-10 lg:py-8',
        className,
      )}
      {...props}
    />
  )
}

ToolBar.displayName = 'ToolBar'

ToolBar.Left = ToolBarLeft
ToolBar.Right = ToolBarRight
export default ToolBar

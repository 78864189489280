import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import type { AccordionStaticProperties } from './Accordion.types'
import AccordionContent from './AccordionContent'
import AccordionItem from './AccordionItem'
import AccordionTrigger from './AccordionTrigger'

const Accordion: AccordionStaticProperties = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>
>((props, ref) => {
  return <AccordionPrimitive.Root ref={ref} {...props} />
}) as AccordionStaticProperties

Accordion.Content = AccordionContent
Accordion.Item = AccordionItem
Accordion.Trigger = AccordionTrigger

export default Accordion

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoSheet } from '@components'

const NotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="grid h-screen place-items-center">
      <InfoSheet title={t('not_found.title')} description={t('not_found.description')} error={t('not_found.error')} />
    </div>
  )
}
export default NotFound

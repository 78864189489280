import * as React from 'react'
import { Icon } from '@components'
import type { ImageProps } from './Image.types'
import { cn } from '@core/utils'
import { useTheme } from '@core/utils'

const Image: React.FC<ImageProps> = ({ className, ...props }) => {
  const theme = useTheme().colors

  const [loading, setLoading] = React.useState<boolean>(true)

  return (
    <React.Fragment>
      {loading && (
        <div
          className={cn(
            'flex w-full animate-pulse items-center justify-center bg-semantic-background-primary',
            className,
          )}>
          <Icon name="gallery" size={24} color={theme.semantic.background.quaternary} />
        </div>
      )}
      <img data-loading={loading} className={cn('hidden', className)} {...props} onLoad={() => setLoading(false)} />
    </React.Fragment>
  )
}
export default Image

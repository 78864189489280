import * as React from 'react'
import type { SectionBarProps } from './SectionBar.types'
import { RenderLayoutRightComponent } from './SectionBar.utils'
import { cn } from '@core/utils'

const SectionBar: React.FC<SectionBarProps> = ({
  title,
  description,
  LayoutRightComponent,
  LayoutRightComponentProps,
  MobileRightComponentProps,
  NavigateLink,
  className,
  ...props
}) => {
  return (
    <div className={cn('space-y-2', className)} {...props}>
      <div className="flex items-center justify-between gap-2">
        <h2 className="animate-enter line-clamp-1 text-display-sm text-semantic-content-ink">{title}</h2>
        <RenderLayoutRightComponent
          LayoutRightComponent={LayoutRightComponent}
          LayoutRightComponentProps={LayoutRightComponentProps}
          MobileRightComponentProps={MobileRightComponentProps}
          NavigateLink={NavigateLink}
        />
      </div>
      {description && <p className="animate-enter text-body-md-r text-semantic-content-inkMedium">{description}</p>}
    </div>
  )
}

export default SectionBar

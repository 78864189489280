import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigation } from '@components'
import { useAppSelector } from '@core/store'
import productsApi from '@products/api'
import { ProductHeader } from '@products/components'
import { sendPostMessage } from '@core/utils'
import ProductsFooter from './ProductsFooter'
import ProductsList from './ProductsList'

const Products: React.FC = () => {
  const { t } = useTranslation()

  const info = useAppSelector(state => state.baseSlice.info)
  const cart = useAppSelector(state => state.cartSlice.products)

  const [open, setOpen] = React.useState<string | null>(null)

  const ProductsQuery = productsApi.useCategoriesQuery({ slug: info?.menu_slug }, { refetchOnMountOrArgChange: true })

  const categories = React.useMemo(() => ProductsQuery.data?.data, [ProductsQuery])

  const handleSendPostMessage = React.useCallback(() => {
    sendPostMessage(JSON.stringify({ event: 'BACK_BUTTON_PRESSED' }))
  }, [])

  if (!info || !categories) return null

  return (
    <React.Fragment>
      <Navigation title={t('product.route_header_title')} onBackPress={handleSendPostMessage} />
      <ProductHeader logo={info.logo} title={info.name} />
      <ProductsList categories={categories} cart={cart} info={info} open={open} setOpen={setOpen} />
      <ProductsFooter cart={cart} branchSlug={info.branch_slug} />
    </React.Fragment>
  )
}
export default Products

import * as React from 'react'
import { IconButton } from '@components'
import type { ProductCardButtonsProps } from './ProductCard.types'

const ProductCardButtons: React.FC<ProductCardButtonsProps> = ({
  isInCart,
  quantity,
  IconButtonIncreaseProps,
  IconButtonDecreaseProps,
  IconButtonProps,
}) => {
  return (
    <React.Fragment>
      {!isInCart && <IconButton name="plus" size="small" {...IconButtonProps} />}
      {isInCart && (
        <div className="flex items-center gap-2 rounded-full bg-solid-brand-1 p-1">
          <IconButton
            name="minus"
            variant="ghost-on-light"
            size="tiny"
            {...IconButtonDecreaseProps}
            className="fill-semantic-content-brand"
            IconProps={{ size: 16 }}
          />
          <span className="text-body-md-b text-semantic-content-brand">{quantity}</span>
          <IconButton
            name="plus"
            variant="ghost-on-light"
            size="tiny"
            {...IconButtonIncreaseProps}
            className="fill-semantic-content-brand"
            IconProps={{ size: 16 }}
          />
        </div>
      )}
    </React.Fragment>
  )
}
export default ProductCardButtons

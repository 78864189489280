import * as React from 'react'
import { useNotification } from '@core/hooks'
import Toast from './Toast'
import ToastViewport from './ToastViewport'

const Toaster = () => {
  const { notifications } = useNotification()

  const toasts = React.useMemo(() => {
    return notifications.filter(({ type }) => type === 'toast')
  }, [notifications])

  return (
    <React.Fragment>
      <ToastViewport isViewing={toasts.length > 0}>
        {toasts.map(function ({ id, ...props }) {
          return <Toast key={id} id={id} {...props} />
        })}
      </ToastViewport>
    </React.Fragment>
  )
}

export default Toaster

import * as React from 'react'
import { map } from 'lodash'
import { Button, Icon, IconButton } from '@components'
import i18n from '@core/translations'
import type { AlertProps } from './Alert.types'
import { useNotification } from '@core/hooks'
import { cn, useTheme } from '@core/utils'
import AlertOverlay from './AlertOverlay'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    { id, open, duration = Infinity, icon = 'warning', iconColor, title = '', message, actions, className, ...props },
    ref,
  ) => {
    const theme = useTheme()
    const { dismiss } = useNotification()
    const [infoTimerLeft, setInfoTimerLeft] = React.useState<number>(duration / 1000)

    React.useEffect(() => {
      if (duration === Infinity) return

      if (open && duration) {
        const timer = setInterval(() => {
          setInfoTimerLeft(prev => {
            if (prev !== 0) return prev - 1
            return prev
          })

          if (infoTimerLeft === 0) return dismiss(id)
        }, 1000)
        return () => clearTimeout(timer)
      }
    }, [dismiss, duration, id, infoTimerLeft, open])

    return (
      <React.Fragment>
        <div
          ref={ref}
          data-state={open}
          className={cn(
            'z-10 w-full max-w-[359px] space-y-2 overflow-hidden rounded-[20px] bg-semantic-background-inkInverse duration-200 data-[state=true]:animate-in data-[state=false]:animate-out data-[state=false]:fade-out-0 data-[state=true]:fade-in-0 data-[state=false]:zoom-out-95 data-[state=true]:zoom-in-95 sm:w-[359px]',
            className,
          )}
          {...props}>
          <div className="flex justify-end p-1">
            <IconButton variant="ghost-on-light" size="small" name="close" onClick={() => dismiss(id)} />
          </div>
          <div className="flex flex-col items-center justify-between gap-[72px] pb-4">
            <div className="flex flex-col items-center gap-6 px-6">
              <Icon name={icon} size={128} color={iconColor ?? theme.colors.semantic.content.info} />
              <div className="space-y-4 px-2 text-center">
                <h4 className="text-display-lg-b text-semantic-content-ink">{title}</h4>
                <p className="text-body-lg-r text-semantic-content-inkMedium">{message}</p>
              </div>
            </div>
            <div className="w-full space-y-3 px-4 text-center">
              <div className="space-y-2">
                {map(actions, (actionButtonProps, index) => (
                  <Button
                    key={index}
                    className="w-full"
                    {...actionButtonProps}
                    onClick={e => {
                      actionButtonProps.onClick?.(e)
                      dismiss(id)
                    }}>
                    {actionButtonProps.children}
                  </Button>
                ))}
              </div>
              {duration !== Infinity ? (
                <p className="text-body-sm-r text-semantic-content-inkLight">
                  {i18n.t('content_config.Alert.close_timer', { time: infoTimerLeft })}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {open ? <AlertOverlay open={open} /> : null}
      </React.Fragment>
    )
  },
)

Alert.displayName = 'Alert'

export default Alert

import * as React from 'react'
import * as ToastPrimitives from '@radix-ui/react-toast'
import type { AlertViewportProps } from '@components'
import { cn } from '@core/utils'

const AlertViewport = React.forwardRef<HTMLDivElement, AlertViewportProps>(
  ({ isViewing, className, ...props }, ref) => {
    if (!isViewing) return null

    return (
      <div
        ref={ref}
        className={cn(
          'fixed top-0 z-[100] flex h-screen w-full flex-col-reverse items-center justify-center gap-2 p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col',
          className,
        )}
        {...props}
      />
    )
  },
)
AlertViewport.displayName = ToastPrimitives.Viewport.displayName

export default AlertViewport

import * as React from 'react'
import productsApi from '@products/api'
import type { ProductDetailProps } from './ProductDetail.types'
import ProductDetailContent from './ProductDetailContent'
import ProductDetailFooter from './ProductDetailFooter'
import ProductDetailImage from './ProductDetailImage'

const ProductDetail: React.FC<ProductDetailProps> = ({ menuSlug, categorySlug, productSlug, setOpen }) => {
  const [selectedVariationId, setSelectedVariationId] = React.useState<number | null>(null)
  const [quantity, setQuantity] = React.useState<number>(1)

  const ProductDetailQuery = productsApi.useProductDetailQuery(
    {
      menuSlug,
      categorySlug,
      productSlug,
    },
    { refetchOnMountOrArgChange: true },
  )

  const product = React.useMemo(() => ProductDetailQuery.data?.data, [ProductDetailQuery])

  if (!product) return null

  return (
    <React.Fragment>
      <div className="mt-2 w-full overflow-y-auto border-t border-semantic-background-tertiary pb-10">
        <ProductDetailImage image={product.detail_image_url} title={product.name} />
        <div className="container flex flex-col gap-10">
          <ProductDetailContent
            title={product.name}
            description={product.description}
            allergies={product.allergies}
            variations={product.variations}
            selectedVariationId={selectedVariationId}
            setSelectedVariationId={setSelectedVariationId}
          />
        </div>
      </div>
      <ProductDetailFooter
        product={product}
        selectedVariationId={selectedVariationId}
        quantity={quantity}
        setQuantity={setQuantity}
        setOpen={setOpen}
      />
    </React.Fragment>
  )
}
export default ProductDetail

import { sumBy } from 'lodash'
import { CurrencyFormatter } from '@macellan/formatter'
import type { ProductCartType } from '@cart/slice'

const calculateCartTotalPrice = (cart: ProductCartType[], locale: string, currency: string): string => {
  if (!cart.length) return '0'

  const totalPrice = sumBy(cart, product => product.variation.price * product.quantity)
  return CurrencyFormatter.format(totalPrice, locale, currency)
}

export default calculateCartTotalPrice

import * as React from 'react'
import { filter, map, sumBy } from 'lodash'
import { BottomSheet, ProductCard, SectionBar } from '@components'
import { ProductDetail } from '@products/views'
import type { ProductsListProps } from './Products.types'

const ProductsList: React.FC<ProductsListProps> = ({ categories, cart, info, open, setOpen }) => {
  return (
    <div className="container space-y-4" style={{ paddingBottom: cart.length > 0 ? 128 : 24 }}>
      {map(categories, (category, index) => (
        <div key={index}>
          <SectionBar
            title={category.name}
            description={category.description}
            className="sticky top-[var(--navigation-height)] z-40 bg-tint-white-60 py-6 backdrop-blur"
          />
          <div className="space-y-2">
            {map(category.products, (product, index) => (
              <BottomSheet
                key={index}
                open={open === product.slug}
                onOpenChange={isOpen => setOpen(isOpen ? product.slug : null)}>
                <BottomSheet.Trigger className="w-full outline-none">
                  <ProductCard
                    title={product.name}
                    description={product.description}
                    price={product.price}
                    image={product.list_image_url}
                    orderCount={sumBy(filter(cart, { slug: product.slug }), 'quantity')}
                    IconButtonProps={{ as: 'div' }}
                  />
                </BottomSheet.Trigger>
                <BottomSheet.Content className="mx-[-1px] h-full max-h-[95%] outline-none">
                  <ProductDetail
                    menuSlug={info.menu_slug}
                    categorySlug={category.slug}
                    productSlug={product.slug}
                    setOpen={setOpen}
                  />
                </BottomSheet.Content>
              </BottomSheet>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
export default ProductsList

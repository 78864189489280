import * as React from 'react'
import { useNotification } from '@core/hooks'
import Alert from './Alert'
import AlertViewport from './AlertViewport'

const Alerter = () => {
  const { notifications } = useNotification()

  const alerts = React.useMemo(() => {
    return notifications.filter(({ type }) => type === 'alert')
  }, [notifications])

  return (
    <React.Fragment>
      <AlertViewport isViewing={alerts.length > 0}>
        {alerts.map(function ({ id, title = '', ...props }) {
          return <Alert key={id} id={id} title={title} {...props} />
        })}
      </AlertViewport>
    </React.Fragment>
  )
}

export default Alerter

import * as React from 'react'
import type { IconButtonProps } from './IconButton.types'
import { IconSize } from './IconButton.utils'
import { cn } from '@core/utils'
import Icon from '../Icon/Icon'
import { iconButtonStyles } from './IconButton.styles'

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ as, variant, size, color, name, className, IconProps, ...props }, ref) => {
    if (typeof name !== 'string') {
      return null
    }

    const Comp = as || 'button'

    return (
      <Comp ref={ref} className={cn(iconButtonStyles({ variant, size, className }))} {...props}>
        <Icon name={name} color={color} size={IconSize(size)} {...IconProps} />
      </Comp>
    )
  },
)

export default IconButton

import { createBrowserRouter, Navigate, type RouteObject } from 'react-router-dom'
import { CartRouter } from '@cart/router'
import { CompanyRouter } from '@company/router'
import Layout from './Layout/Layout'
import NotFound from './NotFound/NotFound'

const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        children: [...CompanyRouter, ...CartRouter],
      },
    ],
  },
  {
    path: '/404',
    element: <NotFound />,
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  },
]

export default routes

export const router = createBrowserRouter(routes)
